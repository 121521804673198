import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import LoadScript from "vue-plugin-load-script";
import {VueMaskDirective} from 'v-mask'
import VueDadata from 'vue-dadata'
import {getters, store} from './store'
import {router} from './router/router'
import VueApexCharts from 'vue-apexcharts'
import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueEllipseProgress);
Vue.directive('mask', VueMaskDirective)
Vue.use(VueDadata)
Vue.prototype.$http = Axios
Vue.config.productionTip = false
// Vue.config.devtools = true
Vue.use(require('vue-moment'))
Vue.use(VueRouter)
Vue.use(LoadScript);

Vue.mixin({
    methods: {
        can(permissionName) {
            if (!store.getters.GET_USER.currentPermissions) {
                return false;
            } else {
                return store.getters.GET_USER.currentPermissions.indexOf(permissionName) == -1 ? false : true;
            }

        },
    },
}
);

require('dotenv').config()

store.commit('initialiseStore')

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
