<template>
    <div>
        <v-container>
            <h4>Вход</h4>

            <v-row>
                <v-col :cols="6">
                    <v-form ref="form" v-model="valid" @submit.prevent="login" lazy-validation>
                        <v-text-field
                                v-model="userData.phone"
                                :rules="rules.phoneRules"
                                v-mask="'+7 (###) ###-##-##'"
                                label="phone"
                                type="tel"
                                @keyup.enter="login"
                                required
                        />

                        <v-text-field
                                v-model="userData.password"
                                :counter="25"
                                :rules="rules.passwordRules"
                                label="password"
                                type="password"
                                @keyup.enter="login"
                                required
                        />

                        <v-btn :disabled="!valid" color="success" class="mr-4" @click="login">
                            Submit
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import RequestService from "../services/RequestService";
    import {mapActions, mapGetters} from "vuex";
    import {converValidationServerErrorsToString} from "../utils/errorUtil";

    require('dotenv').config()

    export default {
        name: "LoginPage",
        data() {
            return {
                valid: true,
                userData: {
                    phone: '',
                    password: ''
                },
                rules: {
                    phoneRules: [
                        v => !!v || 'E-mail is required',
                        v => /\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/.test(v) || 'Phone must be valid',
                    ],
                    passwordRules: [
                        v => !!v || 'Password must be valid',
                        v => v.length > 5 || 'Password length must be more than 6 characters',
                    ],
                }
            }
        },
        methods: {
            ...mapActions([
                'EXEC_PROCESSING_REQUEST',
                'EXEC_AUTH_SUCCESS',
                'EXEC_LOGOUT',
            ]),
            login(e) {
                e.preventDefault()

                if (!this.validate()) {
                    return false
                }

                let okCb = data => {
                    const token = data.data.token
                    const user = data.data.user;
                    this.EXEC_AUTH_SUCCESS({token, user})
                }

                let errCb = err => {
                    alert(converValidationServerErrorsToString(err))
                }

                this.EXEC_PROCESSING_REQUEST(true)

                RequestService.send('/auth/login', this.userData, okCb, errCb).then(() => {
                    this.$nextTick()
                    this.$router.push('/admin/applications')
                })
                    .catch(err => console.log('error__', err))
                    .finally(() => this.EXEC_PROCESSING_REQUEST(false))
            },
            /*computed: {
                ...mapGetters({
                    user: 'GET_USER'
                })
            },*/
            validate() {
                if (!this.userData.phone) {
                    alert('phone is empty');
                    return false;
                }
                if (!this.userData.password) {
                    alert('password is empty');
                    return false;
                }
                return true;
            }
        }
    }
</script>

<style scoped>

</style>
